<template>
  <div>
    <b-row>
      <b-col md="6">
        <b-form-group :label="$t('field.type')">
          <n-single-select
            v-model="question.questionType"
            name="questionType"
            :options="questionTypes"
            selection-key="id"
            selection-label="label"
            :localization="true"
          ></n-single-select>
        </b-form-group>
      </b-col>
      <b-col md="6" class="p-0"> </b-col>
      <b-col md="6">
        <validation-provider
          #default="{ errors }"
          :vid="`questionKm-${sidx}-${qidx}`"
          name="field.questionKm"
          rules="required|max:100"
        >
          <b-form-group :label="$t('field.questionKm')">
            <b-form-input
              v-model="question.questionKm"
              :name="`questionKm-${sidx}-${qidx}`"
              :class="errors.length > 0 ? 'is-invalid' : null"
              :placeholder="$t('field.questionKm')"
            >
            </b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="6">
        <validation-provider
          #default="{ errors }"
          :vid="`questionEn-${sidx}-${qidx}`"
          name="field.questionEn"
          rules="required|max:100"
        >
          <b-form-group :label="$t('field.questionEn')">
            <b-form-input
              v-model="question.questionEn"
              :name="`questionEn-${sidx}-${qidx}`"
              :class="errors.length > 0 ? 'is-invalid' : null"
              :placeholder="$t('field.questionEn')"
            >
            </b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="6">
        <b-form-group :label="$t('field.hintKm')">
          <b-form-input
            v-model="question.hintKm"
            :name="`hintKm-${sidx}-${qidx}`"
            :placeholder="$t('field.hintKm')"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group :label="$t('field.hintEn')">
          <b-form-input
            v-model="question.hintEn"
            :name="`hintEn-${sidx}-${qidx}`"
            :placeholder="$t('field.hintEn')"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group :label="$t('field.prefix')">
          <b-form-input
            v-model="question.prefix"
            :name="`prefix-${sidx}-${qidx}`"
            :placeholder="$t('field.prefix')"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group :label="$t('field.suffix')">
          <b-form-input
            v-model="question.suffix"
            :name="`suffix-${sidx}-${qidx}`"
            :placeholder="$t('field.suffix')"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col md="auto">
        <b-form-group>
          <b-form-checkbox
            v-model="question.isRequired"
            :name="`required-${sidx}-${qidx}`"
            :value="true"
            :unchecked-value="false"
          >
            {{ $t("field.required") }}
          </b-form-checkbox>
        </b-form-group>
      </b-col>
      <b-col md="auto">
        <b-form-group>
          <b-form-checkbox
            v-model="question.isEnable"
            :name="`active-${sidx}-${qidx}`"
            :value="true"
            :unchecked-value="false"
          >
            {{ $t("field.active") }}
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
} from "bootstrap-vue";
import questionTypes from "./data/questionTypes";
import NSingleSelect from "@/components/NSingleSelect";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,

    NSingleSelect,
  },
  props: {
    question: {},
    sidx: {
      default: 0,
    },
    qidx: {
      default: 0,
    },
  },
  data() {
    return {
      questionTypes,
    };
  },
  setup() {},
};
</script>