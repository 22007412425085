export default [
  {
    key: 'name',
    label: 'field.name',
    rules: 'required|max:100',
    type: 'text',
  },
  {
    skip: true,
    hide: true,
    cols: 12,
  },
  {
    key: 'type',
    label: 'field.type',
    rules: 'required',
    type: 'radio',
    options: [
      { text: 'surveyType.0', value: '0' },
      { text: 'surveyType.1', value: '1' },
      { text: 'surveyType.2', value: '2' },
    ],
    cols: "auto",
  },
  {
    skip: true,
    hide: true,
    cols: 12,
  },
]
