export default {
  nameEn: null,
  nameKm: null,
  isShow: true,
  isEnable: true,
  questions: [
    {
      questionEn: null,
      questionKm: null,
      hintEn: null,
      hintKm: null,
      prefix: null,
      suffix: null,
      isRequired: false,
      isEnable: true,
      questionType: 1,
      isShow: true,
      choices: [
        {
          isShow: true,
          answer: null,
          answerLabelEn: null,
          answerLabelKm: null,
          isEnable: true,
        },
      ],
    },
  ],
};