<template>
  <b-card class="survey-builder">
    <draggable :value="value" :group="`sections`" handle=".sortable-s" @input="onChange">
      <div
        class="portlet porlet-section"
        v-for="(section, sidx) in value"
        :key="`section-${sidx}`"
      >
        <div class="portlet-title porlet-title-section sortable-s">
          <div class="caption">
            <div class="row align-items-center justify-content-between">
              <div class="col">
                <b-button
                  variant="light"
                  size="sm"
                  class="mr-1"
                  @click="section.isShow = !section.isShow"
                >
                  <feather-icon
                    v-if="!section.isShow"
                    icon="ChevronDownIcon"
                    size="16"
                  />
                  <feather-icon v-else icon="ChevronUpIcon" size="16" />
                </b-button>
                <span
                  class="caption-subject text-uppercase"
                  :class="{
                    'text-danger': !section.isEnable,
                  }"
                >
                  {{ getSectionName(section, sidx) }}
                </span>
              </div>
              <div class="col-auto">
                <b-button
                  variant="danger"
                  size="sm"
                  @click="removeSection(sidx, section)"
                  v-if="value.length > 1"
                >
                  <feather-icon icon="Trash2Icon" size="16" />
                </b-button>
                <b-button
                  variant="primary"
                  size="sm"
                  @click="addNewSection"
                  v-if="sidx == value.length - 1"
                  class="ml-1"
                >
                  <feather-icon icon="PlusIcon" size="16" />
                </b-button>
              </div>
            </div>
          </div>
        </div>

        <div class="portlet-body" v-if="section.isShow">
          <b-row>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                :vid="`nameKm-${sidx}`"
                name="field.nameKm"
                rules="required|max:100"
              >
                <b-form-group :label="$t('field.nameKm')">
                  <b-form-input
                    :name="`nameKm-${sidx}`"
                    :placeholder="$t('field.nameKm')"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                    v-model="section.nameKm"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                :vid="`nameEn-${sidx}`"
                name="field.nameEn"
                rules="required|max:100"
              >
                <b-form-group :label="$t('field.nameEn')">
                  <b-form-input
                    :name="`nameEn-${sidx}`"
                    :placeholder="$t('field.nameEn')"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                    v-model="section.nameEn"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="auto">
              <b-form-group>
                <b-form-checkbox
                  v-model="section.isEnable"
                  :name="`active-${sidx}`"
                  :value="true"
                  :unchecked-value="false"
                >
                  {{ $t("field.active") }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Questions -->
          <draggable
            :list="section.questions"
            :group="`questions`"
            handle=".sortable-q"
          >
            <div
              class="portlet porlet-question"
              v-for="(question, qidx) in section.questions"
              :key="`question-${sidx}-${qidx}`"
            >
              <div class="portlet-title porlet-title-question sortable-q">
                <div class="caption">
                  <div class="row align-items-center justify-content-between">
                    <div class="col">
                      <b-button
                        variant="light"
                        size="sm"
                        class="mr-1"
                        @click="question.isShow = !question.isShow"
                      >
                        <feather-icon
                          v-if="!question.isShow"
                          icon="ChevronDownIcon"
                          size="16"
                        />
                        <feather-icon v-else icon="ChevronUpIcon" size="16" />
                      </b-button>
                      <span
                        class="caption-subject text-uppercase"
                        :class="{
                          'text-danger': !question.isEnable,
                        }"
                      >
                        {{ getQuestionName(question, qidx) }}
                        {{
                          question.isRequired
                            ? ""
                            : ` - ${$t("field.optional")}`
                        }}
                      </span>
                    </div>
                    <div class="col-auto">
                      <b-button
                        variant="danger"
                        size="sm"
                        @click="removeQuestion(sidx, qidx, question)"
                        v-if="section.questions.length > 1"
                      >
                        <feather-icon icon="Trash2Icon" size="16" />
                      </b-button>
                      <b-button
                        variant="primary"
                        size="sm"
                        @click="addNewQuestion(sidx)"
                        v-if="qidx == section.questions.length - 1"
                        class="ml-1"
                      >
                        <feather-icon icon="PlusIcon" size="16" />
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="portlet-body" v-if="question.isShow">
                <text-form
                  :key="`text-form-${sidx}-${qidx}`"
                  :question="question"
                  :sidx="sidx"
                  :qidx="qidx"
                  v-if="[1, 4].includes(question.questionType)"
                />
                <number-form
                  :key="`number-form-${sidx}-${qidx}`"
                  :question="question"
                  :sidx="sidx"
                  :qidx="qidx"
                  v-if="question.questionType == 2"
                />
                <multiple-choice-form
                  :key="`multiple-choice-form-${sidx}-${qidx}`"
                  :question="question"
                  :sidx="sidx"
                  :qidx="qidx"
                  v-if="question.questionType == 3"
                />
              </div>
            </div>
          </draggable>
        </div>
      </div>
    </draggable>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BButton,
} from "bootstrap-vue";
import draggable from "vuedraggable";
import defaultSection from "./data/defaultSection";
import defaultQuestion from "./data/defaultQuestion";
import TextForm from "./TextForm.vue";
import NumberForm from "./NumberForm.vue";
import MultipleChoiceForm from "./MultipleChoiceForm.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,

    TextForm,
    NumberForm,
    MultipleChoiceForm,

    draggable,
  },
  props: {
    value: {},
  },
  data() {
    return {};
  },
  methods: {
    onChange(ev) {
      this.$emit('input', ev);
    },
    getSectionName(section, sidx) {
      return this.trans(section, "name", this.$i18n.locale)
        ? this.trans(section, "name", this.$i18n.locale)
        : this.$t("general.section", { value: sidx + 1 });
    },
    removeSection(sidx, section) {
      this.$bvModal
        .msgBoxConfirm(
          `${this.$t("alert.deleteSection")} (${this.getSectionName(
            section,
            sidx
          )})`,
          {
            title: this.$t("alert.areYouSure"),
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: this.$t("button.delete"),
            cancelTitle: this.$t("button.cancel"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            let sections = [...this.value];
            sections.splice(sidx, 1);
            this.emitInput(sections);
          }
        });
    },
    addNewSection() {
      let sections = [...this.value];
      let section = JSON.parse(JSON.stringify(defaultSection));
      sections.push({
        ...section,
      });
      this.emitInput(sections);
    },
    getQuestionName(question, qidx) {
      return this.trans(question, "question", this.$i18n.locale)
        ? this.trans(question, "question", this.$i18n.locale)
        : this.$t("general.question", { value: qidx + 1 });
    },
    removeQuestion(sidx, qidx, question) {
      this.$bvModal
        .msgBoxConfirm(
          `${this.$t("alert.deleteQuestion")} (${this.getQuestionName(
            question,
            qidx
          )})`,
          {
            title: this.$t("alert.areYouSure"),
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: this.$t("button.delete"),
            cancelTitle: this.$t("button.cancel"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.value[sidx].questions.splice(qidx, 1);
          }
        });
    },
    addNewQuestion(sidx) {
      let question = JSON.parse(JSON.stringify(defaultQuestion));
      this.value[sidx].questions.push({ ...question });
    },
    emitInput(sections) {
      this.$emit("input", sections);
    },
  },
  setup() {},
};
</script>