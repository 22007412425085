export default [
  {
    id: 1,
    labelEn: "Text",
    labelKm: "អត្ថបទ",
  },
  {
    id: 2,
    labelEn: "Number",
    labelKm: "ចំនួន",
  },
  {
    id: 3,
    labelEn: "Multiple Choices One Answer",
    labelKm: "ជម្រើសច្រើន ចម្លើយតែមួយ",
  },
  {
    id: 4,
    labelEn: "Rating",
    labelKm: "ការវាយតម្លៃ",
  },
];