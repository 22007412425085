<template>
  <div>
    <b-row>
      <b-col md="6">
        <b-form-group :label="$t('field.type')">
          <n-single-select
            v-model="question.questionType"
            name="questionType"
            :options="questionTypes"
            selection-key="id"
            selection-label="label"
            :localization="true"
          ></n-single-select>
        </b-form-group>
      </b-col>
      <b-col md="6" class="p-0"> </b-col>
      <b-col md="6">
        <validation-provider
          #default="{ errors }"
          :vid="`questionKm-${sidx}-${qidx}`"
          name="field.questionKm"
          rules="required|max:100"
        >
          <b-form-group :label="$t('field.questionKm')">
            <b-form-input
              v-model="question.questionKm"
              :name="`questionKm-${sidx}-${qidx}`"
              :class="errors.length > 0 ? 'is-invalid' : null"
              :placeholder="$t('field.questionKm')"
            ></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="6">
        <validation-provider
          #default="{ errors }"
          :vid="`questionEn-${sidx}-${qidx}`"
          name="field.questionEn"
          rules="required|max:100"
        >
          <b-form-group :label="$t('field.questionEn')">
            <b-form-input
              v-model="question.questionEn"
              :name="`questionEn-${sidx}-${qidx}`"
              :class="errors.length > 0 ? 'is-invalid' : null"
              :placeholder="$t('field.questionEn')"
            ></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="6">
        <b-form-group :label="$t('field.hintKm')">
          <b-form-input
            v-model="question.hintKm"
            :name="`hintKm-${sidx}-${qidx}`"
            :placeholder="$t('field.hintKm')"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group :label="$t('field.hintEn')">
          <b-form-input
            v-model="question.hintEn"
            :name="`hintEn-${sidx}-${qidx}`"
            :placeholder="$t('field.hintEn')"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col md="auto">
        <b-form-group>
          <b-form-checkbox
            v-model="question.isRequired"
            :name="`required-${sidx}-${qidx}`"
            :value="true"
            :unchecked-value="false"
          >
            {{ $t("field.required") }}
          </b-form-checkbox>
        </b-form-group>
      </b-col>
      <b-col md="auto">
        <b-form-group>
          <b-form-checkbox
            v-model="question.isEnable"
            :name="`active-${sidx}-${qidx}`"
            :value="true"
            :unchecked-value="false"
          >
            {{ $t("field.active") }}
          </b-form-checkbox>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <draggable
          :list="question.choices"
          :group="`choices`"
          handle=".sortable-c"
        >
          <div
            class="portlet porlet-choice"
            v-for="(choice, cidx) in question.choices"
            :key="`choice-${cidx}`"
          >
            <div class="portlet-title porlet-title-choice sortable-c">
              <div class="caption">
                <div class="row align-items-center justify-content-between">
                  <div class="col">
                    <b-button
                      variant="light"
                      size="sm"
                      class="mr-1"
                      @click="choice.isShow = !choice.isShow"
                    >
                      <feather-icon
                        v-if="!choice.isShow"
                        icon="ChevronDownIcon"
                        size="16"
                      />
                      <feather-icon v-else icon="ChevronUpIcon" size="16" />
                    </b-button>
                    <span
                      class="caption-subject text-uppercase"
                      :class="{
                        'text-danger': !choice.isEnable,
                      }"
                    >
                      {{ getChoiceName(choice, cidx) }}
                    </span>
                  </div>
                  <div class="col-auto">
                    <b-button
                      variant="danger"
                      size="sm"
                      @click="removeChoice(cidx, choice)"
                      v-if="question.choices.length > 1"
                    >
                      <feather-icon icon="Trash2Icon" size="16" />
                    </b-button>
                    <b-button
                      variant="primary"
                      size="sm"
                      @click="addNewChoice()"
                      v-if="cidx == question.choices.length - 1"
                      class="ml-1"
                    >
                      <feather-icon icon="PlusIcon" size="16" />
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="portlet-body" v-if="choice.isShow">
              <b-row>
                <b-col md="4">
                  <validation-provider
                    #default="{ errors }"
                    :vid="`answer-${sidx}-${qidx}-${cidx}`"
                    name="field.answer"
                    rules="required|max:100"
                  >
                    <b-form-group :label="$t('field.answer')">
                      <b-form-input
                        v-model="choice.answer"
                        :name="`answer-${sidx}-${qidx}-${cidx}`"
                        :placeholder="$t('field.answer')"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      ></b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="4">
                  <validation-provider
                    #default="{ errors }"
                    :vid="`labelKm-${sidx}-${qidx}-${cidx}`"
                    name="field.labelKm"
                    rules="required|max:100"
                  >
                    <b-form-group :label="$t('field.labelKm')">
                      <b-form-input
                        v-model="choice.answerLabelKm"
                        :name="`labelKm-${sidx}-${qidx}-${cidx}`"
                        :placeholder="$t('field.labelKm')"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      ></b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="4">
                  <validation-provider
                    #default="{ errors }"
                    :vid="`labelEn-${sidx}-${qidx}-${cidx}`"
                    name="field.labelEn"
                    rules="required|max:100"
                  >
                    <b-form-group :label="$t('field.labelEn')">
                      <b-form-input
                        v-model="choice.answerLabelEn"
                        :name="`labelEn-${sidx}-${qidx}-${cidx}`"
                        :placeholder="$t('field.labelEn')"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      ></b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="auto">
                  <b-form-group>
                    <b-form-checkbox
                      v-model="choice.isEnable"
                      :name="`active-${sidx}-${qidx}-${cidx}`"
                      :value="true"
                      :unchecked-value="false"
                    >
                      {{ $t("field.active") }}
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>
        </draggable>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BButton,
} from "bootstrap-vue";
import draggable from "vuedraggable";
import questionTypes from "./data/questionTypes";
import NSingleSelect from "@/components/NSingleSelect";
import defaultChoice from "./data/defaultChoice";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,

    draggable,

    NSingleSelect,
  },
  props: {
    question: {},
    sidx: {
      default: 0,
    },
    qidx: {
      default: 0,
    },
  },
  data() {
    return {
      questionTypes,
    };
  },
  methods: {
    getChoiceName(choice, cidx) {
      return this.trans(choice, "answerLabel", this.$i18n.locale)
        ? this.trans(choice, "answerLabel", this.$i18n.locale)
        : this.$t("general.choice", { value: cidx + 1 });
    },
    removeChoice(cidx, choice) {
      this.$bvModal
        .msgBoxConfirm(
          `${this.$t("alert.deleteChoice")} (${this.getChoiceName(
            choice,
            cidx
          )})`,
          {
            title: this.$t("alert.areYouSure"),
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: this.$t("button.delete"),
            cancelTitle: this.$t("button.cancel"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.question.choices.splice(cidx, 1);
          }
        });
    },
    addNewChoice() {
      let choice = JSON.parse(JSON.stringify(defaultChoice));
      this.question.choices.push({ ...choice });
    },
  },
  setup() {},
};
</script>