var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"survey-builder"},[_c('draggable',{attrs:{"value":_vm.value,"group":"sections","handle":".sortable-s"},on:{"input":_vm.onChange}},_vm._l((_vm.value),function(section,sidx){return _c('div',{key:("section-" + sidx),staticClass:"portlet porlet-section"},[_c('div',{staticClass:"portlet-title porlet-title-section sortable-s"},[_c('div',{staticClass:"caption"},[_c('div',{staticClass:"row align-items-center justify-content-between"},[_c('div',{staticClass:"col"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"light","size":"sm"},on:{"click":function($event){section.isShow = !section.isShow}}},[(!section.isShow)?_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"16"}}):_c('feather-icon',{attrs:{"icon":"ChevronUpIcon","size":"16"}})],1),_c('span',{staticClass:"caption-subject text-uppercase",class:{
                  'text-danger': !section.isEnable,
                }},[_vm._v(" "+_vm._s(_vm.getSectionName(section, sidx))+" ")])],1),_c('div',{staticClass:"col-auto"},[(_vm.value.length > 1)?_c('b-button',{attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.removeSection(sidx, section)}}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon","size":"16"}})],1):_vm._e(),(sidx == _vm.value.length - 1)?_c('b-button',{staticClass:"ml-1",attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.addNewSection}},[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"16"}})],1):_vm._e()],1)])])]),(section.isShow)?_c('div',{staticClass:"portlet-body"},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"vid":("nameKm-" + sidx),"name":"field.nameKm","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('field.nameKm')}},[_c('b-form-input',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"name":("nameKm-" + sidx),"placeholder":_vm.$t('field.nameKm')},model:{value:(section.nameKm),callback:function ($$v) {_vm.$set(section, "nameKm", $$v)},expression:"section.nameKm"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"vid":("nameEn-" + sidx),"name":"field.nameEn","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('field.nameEn')}},[_c('b-form-input',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"name":("nameEn-" + sidx),"placeholder":_vm.$t('field.nameEn')},model:{value:(section.nameEn),callback:function ($$v) {_vm.$set(section, "nameEn", $$v)},expression:"section.nameEn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"auto"}},[_c('b-form-group',[_c('b-form-checkbox',{attrs:{"name":("active-" + sidx),"value":true,"unchecked-value":false},model:{value:(section.isEnable),callback:function ($$v) {_vm.$set(section, "isEnable", $$v)},expression:"section.isEnable"}},[_vm._v(" "+_vm._s(_vm.$t("field.active"))+" ")])],1)],1)],1),_c('draggable',{attrs:{"list":section.questions,"group":"questions","handle":".sortable-q"}},_vm._l((section.questions),function(question,qidx){return _c('div',{key:("question-" + sidx + "-" + qidx),staticClass:"portlet porlet-question"},[_c('div',{staticClass:"portlet-title porlet-title-question sortable-q"},[_c('div',{staticClass:"caption"},[_c('div',{staticClass:"row align-items-center justify-content-between"},[_c('div',{staticClass:"col"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"light","size":"sm"},on:{"click":function($event){question.isShow = !question.isShow}}},[(!question.isShow)?_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"16"}}):_c('feather-icon',{attrs:{"icon":"ChevronUpIcon","size":"16"}})],1),_c('span',{staticClass:"caption-subject text-uppercase",class:{
                        'text-danger': !question.isEnable,
                      }},[_vm._v(" "+_vm._s(_vm.getQuestionName(question, qidx))+" "+_vm._s(question.isRequired ? "" : (" - " + (_vm.$t("field.optional"))))+" ")])],1),_c('div',{staticClass:"col-auto"},[(section.questions.length > 1)?_c('b-button',{attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.removeQuestion(sidx, qidx, question)}}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon","size":"16"}})],1):_vm._e(),(qidx == section.questions.length - 1)?_c('b-button',{staticClass:"ml-1",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.addNewQuestion(sidx)}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"16"}})],1):_vm._e()],1)])])]),(question.isShow)?_c('div',{staticClass:"portlet-body"},[([1, 4].includes(question.questionType))?_c('text-form',{key:("text-form-" + sidx + "-" + qidx),attrs:{"question":question,"sidx":sidx,"qidx":qidx}}):_vm._e(),(question.questionType == 2)?_c('number-form',{key:("number-form-" + sidx + "-" + qidx),attrs:{"question":question,"sidx":sidx,"qidx":qidx}}):_vm._e(),(question.questionType == 3)?_c('multiple-choice-form',{key:("multiple-choice-form-" + sidx + "-" + qidx),attrs:{"question":question,"sidx":sidx,"qidx":qidx}}):_vm._e()],1):_vm._e()])}),0)],1):_vm._e()])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }